import React, { Component } from 'react';
import { Form, Button } from 'reactstrap';
import { Guess } from './Guess';

import './Wordle.css';

export class Wordle extends Component {
    static displayName = Wordle.name;

    clearChildren = [];

    constructor(props) {
        super(props);
        this.state = { results: [], texts: ['', '', '', '', '', ''] };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.guessUpdated = this.guessUpdated.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.assignClearState = this.assignClearState.bind(this);

        this.input = [
            "guess0",
            "guess1",
            "guess2",
            "guess3",
            "guess4",
            "guess5"
        ];

        document.title = "Wordle Solver";
    }

    handleReset(e) {
        e.preventDefault();

        this.setState({ results: [], texts: ['', '', '', '', '', ''] });
        this.clearChildren.forEach(e => {
            e();
        });
    }

    async handleSubmit(e) {
        e.preventDefault();

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.texts)
        };

        fetch('wordle', settings)
            .then(response => response.json())
            .then(data => this.setState({ results: data, texts: this.state.texts }));
    }

    renderInput = (input) => {
        return (
            <Guess key={input.key} />
        );
    }

    guessUpdated(index, value) {
        var currentTexts = this.state.texts;
        currentTexts[index] = value;

        this.setState({ results: this.state.results, texts: currentTexts });
    }

    assignClearState(index, func) {
        this.clearChildren.splice(index, 0, func);
    }

    render() {
        return (
            <div>
                <h1>Wordle  Solver</h1>
                <div>Use <span className="bold">?</span> after the letter to show it's used but in the wrong location. Use <span className="bold">!</span> after the letter to show it's in the correct position.</div>
                <Form onSubmit={this.handleSubmit} onReset={this.handleReset}>
                    {this.input.map((e, i) => <Guess key={e + '-' + i} text={this.state.texts[i]} onUpdate={x => this.guessUpdated(i, x)} clearState={x => this.assignClearState(i, x)} />)}
                    

                    <Button type="submit" className="btn btn-primary mt-2">Submit</Button>
                    <Button type="reset" className="btn btn-primary ms-2 mt-2">Clear</Button>
                </Form>

                <div>
                    The number shown next to the word match is how many distinct letter matches there are with other words in the list. Higher numbers mean more words can be filtered out.
                    <div>
                        Total results: {this.state.results?.totalCount ?? 0}
                    </div>
                    <ul>
                        {this.state.results?.wordResults?.map(function (r) {
                            return (
                                <li>{r.word} - {r.count}</li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    }
}
