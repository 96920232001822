import React, { Component, createRef } from 'react';
import { Input, Label, Col, Row } from 'reactstrap';
import { Letter } from './Letter';
import { uniqueId } from 'lodash';

export class Guess extends Component {
    static displayName = Guess.name;

    constructor(props) {
        super(props);
        this.state = { text: props.text ?? '' };
        this.id = uniqueId("guess-");
        this.textRef = createRef();
        this.clearState = this.clearState.bind(this);

        if (props.clearState) {
            props.clearState(this.clearState);
        }
    }

    updateText = (e) => {
        this.setState({ text: e.target.value });
    };

    shouldComponentUpdate(prevProps, prevState) {
        return this.state.text != prevState.text;
    }

    componentDidUpdate() {
        if (this.props.onUpdate) {
            this.props.onUpdate(this.state.text);
        }
    }

    clearState() {
        this.setState({ text: '' });
    }

    render() {
        return (
            <div>
                <Label for={this.id}>Guess</Label>
                <Row>
                    <Col sm={2}>
                        <Input id={this.id} value={this.state.text} ref={this.textRef} onChange={this.updateText} />
                    </Col>
                    <Col sm={2}>
                        <Letter text={this.textRef.current?.value ?? this.state.text} key="letter1" />
                    </Col>
                </Row>
            </div>
        );
    }
}