import React, { Component } from 'react';
import { Container } from 'reactstrap';

import './Layout.css';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div>
                <Container>
                    {this.props.children}
                </Container>
                <div className="footer-spacer">&nbsp;</div>
                <div className="fixed-bottom pb-3 ps-5 footer">&copy; 2022 Justin Sommercorn</div>
            </div>
        );
    }
}
